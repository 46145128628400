import React from "react";
import style from "./search.module.css";

const Search = ({term, handleTerm, onSubmit}) => {
  return (
    <>
      <div className={style.container}>
        {/* <form> */}
          <div className={style.inputWrap}>
            <input type="text" placeholder="Looking for... " value={term} onChange={handleTerm}/>
            <div className={style.searchBtnWrap}>
              <button type="submit" onClick={onSubmit}>Search</button>
            </div>
          </div>
        {/* </form> */}
      </div>
    </>
  );
};

export default Search;
