import React from 'react'
import {IMAGES} from '../../../assests'
import styles from './comments.module.css'
import Carousel from 'react-multi-carousel';

const data = [{
 id:1,
 comment:'My buddy loves the food. He is all hail and healthy since we switched to your products. It’s good that we get our order delivered on time.',
 username: 'Rita Skeeter',
 location: 'Bengaluru'
}, {
 id:2,
 comment:'My buddy loves the food. He is all hail and healthy since we switched to your products. It’s good that we get our order delivered on time.',
 username: 'Rita Skeeter',
 location: 'Chennai'
}, {
 id:3,
 comment:'My buddy loves the food. He is all hail and healthy since we switched to your products. It’s good that we get our order delivered on time.',
 username: 'Rita Skeeter',
 location: 'Hyderabad'
},]


const responsive = {
 superLargeDesktop: {
   // the naming can be any, depends on you.
   breakpoint: { max: 4000, min: 3000 },
   items: 1
 },
 desktop: {
   breakpoint: { max: 3000, min: 1024 },
   items: 1
 },
 tablet: {
   breakpoint: { max: 1024, min: 464 },
   items: 1
 },
 mobile: {
   breakpoint: { max: 464, min: 0 },
   items: 1
 }
};

function Comments() {
 const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      className={active ? styles.active : styles.inactive}
      onClick={() => onClick()}
    >
    </li>
  );
};
 return (
  <div className={styles.carousel_conatiner}>
   <img src={IMAGES.WOMEN_DOG} alt='dogloverimage' className={styles.women_image}/>
   <div id="main-slide" className={styles.carousel_outside} data-ride="carousel">
        <div className={styles.carousel_inner}>
        <Carousel responsive={responsive}
            arrows={false}
            showDots
            dotListClass={styles.dots}
            customDot={<CustomDot />}>
  { data.map((item) => <div className={styles.carousel}>
      <img src={IMAGES.QOUTES} alt="quotes" className={styles.quotes} />
      <p className={styles.comment}>{item.comment}</p>
      <p className={styles.user_info}>{item.username}</p>
      <p className={styles.user_info}>{item.location}</p>
     </div>
    )
    }
 </Carousel>
        </div>
      </div>
  </div>
 )
}

export default Comments
