import {
  BrowserRouter as Router,
  // Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AboutUs from "./containers/AboutUs";
import Contact from "./containers/Contact";
import Landing from "./containers/Landing";
import PartnerShip from "./containers/PartnerShip";
import ProductDesc from "./containers/ProductDesc";
import Shop from "./containers/Shop";
import Insights from "./containers/Insights";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/aboutUs" component={AboutUs} />
        <Route path="/shop" component={Shop} />
        <Route path="/product/:id" component={ProductDesc} />
        <Route path="/partnerShip" component={PartnerShip} />
        <Route path="/contact" component={Contact} />
        <Route path="/Insights" component={Insights} />
      </Switch>
    </Router>
  );
}

export default App;
