import React from "react";
import { IMAGES } from "../../../assests";
import style from "./ourCommunity.module.css";

const OurCommunity = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.imgWrap}>
          <img src={IMAGES.WOMEN_WITH_DOG} alt="" />
        </div>
        <div className={style.contentWrap}>
          <p className={style.contentHeader}>
            Our <span> Community</span>
          </p>
          <p className={style.contentDesc}>
            At Multimark India Group, our commitment to happy healthy pets is
            our driving motivation. We have a unique opportunity to help pets in
            need. <br /> That’s why we donate our time and talent to care for
            those pets with the same love and dedication that we show for our
            very own pets. <br /> We bring together our unique expertise and
            products, our brand and retail partners and the passion of our
            employees to make this vision a reality in are communities.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurCommunity;
