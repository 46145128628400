import React from "react";
import { useHistory } from "react-router-dom";
// import Header from '../../components/Header'
// import Footer from '../../components/Footer'
import Categories from "../../components/landing/Categories";
import Carousel from "../../components/landing/Carousel";
import Comments from "../../components/landing/Comments";
import OurBrands from "../../components/landing/OurBrands";
import styles from "./landing.module.css";
import { IMAGES } from "../../assests";
import ContactAddress from "../../components/landing/Contact Address";
import Hero from "../../components/landing/Hero";
import { categories } from "../../data";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

function Landing() {
  const history = useHistory();

  const categoryPath = (id) => {
    history.push(`/shop/${id}`);
  };

  const productPath = (id) => {
    history.push(`/product/${id}`);
  };

  return (
    <MainContainer className={styles.landing}>
      {/* <div className={styles.header} style={headStyle}>
  <Header />
  </div> */}
      <Hero />
      <div className={styles.categories}>
        <Categories
          btnText="Shop Now"
          goTo={categoryPath}
          categories={categories}
        />
      </div>
      <div className={styles.shop}>
        <h2>
          Shop with <span>US</span>
        </h2>
        <span className={styles.img_container}>
          <img
            src={IMAGES.BONE_BISCUIT}
            alt="bone-shape-biscuit"
            className={styles.bone_biscuit_img}
          />
        </span>
        <Carousel data={categories} btnText="Shop Now" goTo={productPath} />
        <div className={styles.comments}>
          {/* <Comments /> */}
        </div>
      </div>
      <div className={styles.brands_main}>
        <div className={styles.brands_child}>
          <OurBrands />
        </div>
      </div>
      <ContactAddress />
      {/* <Footer /> */}
    </MainContainer>
  );
}

export default Landing;
