import {IMAGES} from '../assests'

// export const data = [
//  {
//   id: 'd1',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef',
//   oldPrice: 600,
//   newPrice: 400,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Me-O',
//   category: 'dogs',
//   contact: 9845195449,
//   related: ['d2', 'd3']
  
//  }, 
//  {
//   id: 'd2',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef2',
//   oldPrice: 200,
//   newPrice: 100,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Smart Heart',
//   category: 'dogs',
//   contact: 9845195449,
//   related: ['d1', 'd3']
//  },
//  {
//   id: 'd4',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef4',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'dogs',
//   contact: 9845195449,
//   related: ['d2', 'd1']
//     },
//  {
//   id: 'd3',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'dogs',
//   contact: 9845195449,
//   related: ['d2', 'd1']
//  },
//  {
//   id: 'c1',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef',
//   oldPrice: 600,
//   newPrice: 400,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Me-O',
//   category: 'cats',
//   contact: 9845195449,
//   related: ['c2', 'c3']
//  }, 
//  {
//   id: 'c2',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef2',
//   oldPrice: 200,
//   newPrice: 100,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Smart Heart',
//   category: 'cats',
//   contact: 9845195449,
//   related: ['c1', 'c3']
//  },
//  {
//   id: 'c3',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'cats',
//   contact: 9845195449,
//   related: ['c2', 'c1']
//  }, {
//   id: 'c4',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'cats',
//   contact: 9845195449,
//   related: ['c2', 'c1']
//  },
//  {
//   id: 'b1',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef',
//   oldPrice: 600,
//   newPrice: 400,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Me-O',
//   category: 'birds',
//   contact: 9845195449,
//   related: ['b2', 'b3']
//  }, 
//  {
//   id: 'b2',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef2',
//   oldPrice: 200,
//   newPrice: 100,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Smart Heart',
//   category: 'birds',
//   contact: 9845195449,
//   related: ['b1', 'b3']
//  },
//  {
//   id: 'b3',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'birds',
//   contact: 9845195449,
//   related: ['b2', 'b1']
//  }, {
//   id: 'b4',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'birds',
//   contact: 9845195449,
//   related: ['b2', 'b1']
//  },
//  {
//   id: 'f1',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef',
//   oldPrice: 600,
//   newPrice: 400,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Me-O',
//   category: 'Fish',
//   contact: 9845195449,
//   related: ['f2', 'f3']
//  }, 
//  {
//   id: 'f2',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef2',
//   oldPrice: 200,
//   newPrice: 100,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Smart Heart',
//   category: 'Fish',
//   contact: 9845195449,
//   related: ['f1', 'f3']
//  },
//  {
//   id: 'f3',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'Fish',
//   contact: 9845195449,
//   related: ['f2', 'f1']
//  }, {
//   id: 'f4',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'Fish',
//   contact: 9845195449,
//   related: ['f2', 'f1']
//  },
//  {
//   id: 'sp1',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef',
//   oldPrice: 600,
//   newPrice: 400,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Me-O',
//   category: 'Small Pets',
//   contact: 9845195449,
//   related: ['sp2', 'sp3']
//  }, 
//  {
//   id: 'sp2',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef2',
//   oldPrice: 200,
//   newPrice: 100,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Smart Heart',
//   category: 'Small Pets',
//   contact: 9845195449,
//   related: ['sp1', 'sp3']
//  },
//  {
//   id: 'sp3',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'Small Pets',
//   contact: 9845195449,
//   related: ['sp2', 'sp1']
//     },
//   {
//   id: 'sp4',
//   img: IMAGES.PRODUCT,
//   ratings: [1,2,4,5],
//   title: 'Air Dried Beef3',
//   oldPrice: 500,
//   newPrice: 300,
//   description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.',
//   productId: 'SKU: BT-VAR',
//   brandName: 'Happy Cat',
//   category: 'Small Pets',
//   contact: 9845195449,
//   related: ['sp2', 'sp1']
//  },
// ];



export const catCategoryItems = [
  // {
  //   id: "c1",
  //   img: IMAGES.Me_O_Persian_Adult_Cat_Food_Dry,
  //   ratings: [1, 2, 3, 4, 5],
  //   title: "Me-O Persian Adult Cat Food Dry",
  //   subTitle: "Anti Hairball Formula",
  //   oldPrice: 600,
  //   size: [
  //     {
  //       id: 1,
  //       price: 1890.00,
  //       weight: "6.8 Kgs",
  //     },
  //   ],
  //   description:"Suitable For Adult Persian Cats Of Above 1 Year Age. Made From Seafood. Contains Taurine - Improves Cat's Eyesight. Nourishes Cat's Skin & Hair Complete & Balanced Nutrition For Cats. Made With High Quality Ingredients. High In Vit C, O-3 & )-6. No Artificial Flavourings/Preservatives. Packed With Calcium, Phosphorus & Vit-D. A Perfect Balance Of Vitamins & Minerals",
  //   brandName: "Me-O",
  //   category: "cats",
  //   contact: 9845195449,
  //   related: ["c2", "c3"],
  // },
  // {
  //   id: "c2",
  //   img: IMAGES.Me_O_Persian_Adult_Cat_Food_Dry,
  //   ratings: [1, 2, 3, 4, 5],
  //   title: "Me-O Persian Kitten Cat Food Dry",
  //   subTitle: "Anti Hairball Formula",
  //   oldPrice: 600,
  //   size: [
  //     {
  //       id: 1,
  //       price: 1990.00,
  //       weight: "6.8 Kgs",
  //     },
  //   ],
  //   description:"Me-O Persian Kitten is formulated specially to cater the needs of Persian Kittens. During the growth period, Persian Kittens need food which is rich in vitamins and minerals that supports an ideal growth",
  //   brandName: "Me-O",
  //   category: "cats",
  //   contact: 9845195449,
  //   related: ["c2", "c3"],
  // },

  {
    id: "c3",
    img: IMAGES.Bentonite_Happy_Cat_Litter,
    ratings: [1, 2, 3, 4, 5],
    title: "Bentonite Happy Cat Litter",
    subTitle: "Happy Cat",
    oldPrice: 600,
    size: [{
      id:1,
      price: 440.00,
      weight: '5 Kgs' 
    },
    {
      id:2,
      price: 725.00,
      weight: '10 Kgs' 
    },
    {
      id:3,
      price: 1950.00,
      weight: '25 Kgs' 
    },
  ],
    description: 'Outstanding odour control. Has very high liquid absorption rate. Clumps quickly. Clumping cat litter',
    brandName: 'Happy Cat',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c4',
    img: IMAGES.Bentonite_Happy_Cat_Litter,
    ratings: [1,2,3,4,5],
    title: 'Bentonite Happy Cat Litter Lemon',
    subTitle: 'Lemon Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 480.00,
      weight: '5 Kgs' 
    },
  ],
    description: 'Outstanding odour control. Has very high liquid absorption rate. Clumps quickly. Clumping cat litter',
    brandName: 'Happy Cat',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },
   {
    id: 'c5',
    img: IMAGES.Bentonite_Happy_Cat_Litter,
    ratings: [1,2,3,4,5],
    title: 'Bentonite Happy Cat Litter Lavender ',
    subTitle: 'Lavendoer Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 480.00,
      weight: '5 Kgs' 
    },
  ],
    description: 'Outstanding odour control. Has very high liquid absorption rate. Clumps quickly. Clumping cat litter',
    brandName: 'Happy Cat',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c6',
    img: IMAGES.Kat_Kun_Stick_Chicken_Flavour,
    ratings: [1,2,3,4,5],
    title: 'Kat Kun Stick Chicken Flavour',
    subTitle: 'Chicken Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 140.00,
      weight: '40 gms' 
    },
  ],
    description: 'Irresistible Tasty Chicken Flavour, Suitable For Adult Cats, 1+Year Old. Crunchy On The Outside, Soft On The Inside. Rich In Protein, Vitamins & Minerals. Comes In Resealable Pouch, 100% Nutritionally Complete & Balanced For Adult Cats. Under 2 Calories Per Piece. No Artificial Flavors',
    brandName: 'Kat kun',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c7',
    img: IMAGES.Kat_Kun_Stick_Salmon_Flavour,
    ratings: [1,2,3,4,5],
    title: 'Kat Kun Stick Salmon Flavour',
    subTitle: 'Salmon Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 140.00,
      weight: '40 gms' 
    },
  ],
    description: 'Irresistible Tasty Chicken Flavour, Suitable For Adult Cats, 1+Year Old. Crunchy On The Outside, Soft On The Inside. Rich In Protein, Vitamins & Minerals. Comes In Resealable Pouch, 100% Nutritionally Complete & Balanced For Adult Cats. Under 2 Calories Per Piece. No Artificial Flavors',
    brandName: 'Kat kun',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c8',
    img: IMAGES.Kat_Kun_Stick_Tuna_Flavour,
    ratings: [1,2,3,4,5],
    title: 'Kat Kun Stick Tuna Flavour',
    subTitle: 'Tuna Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 140.00,
      weight: '40 gms' 
    },
  ],
    description: 'Irresistible Tasty Chicken Flavour, Suitable For Adult Cats, 1+Year Old. Crunchy On The Outside, Soft On The Inside. Rich In Protein, Vitamins & Minerals. Comes In Resealable Pouch, 100% Nutritionally Complete & Balanced For Adult Cats. Under 2 Calories Per Piece. No Artificial Flavors',
    brandName: 'Kat kun',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c9',
    img: IMAGES.Kat_Kun_Stick_Shrimp_Flavour,
    ratings: [1,2,3,4,5],
    title: 'Kat Kun Stick Shrimp Flavour',
    subTitle: 'Tuna Flavor',
    oldPrice: 600,
    size: [{
      id:1,
      price: 140.00,
      weight: '40 gms' 
    },
  ],
    description: 'Irresistible Tasty Chicken Flavour, Suitable For Adult Cats, 1+Year Old. Crunchy On The Outside, Soft On The Inside. Rich In Protein, Vitamins & Minerals. Comes In Resealable Pouch, 100% Nutritionally Complete & Balanced For Adult Cats. Under 2 Calories Per Piece. No Artificial Flavors',
    brandName: 'Kat kun',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },
   
   {
    id: 'c10',
    img: IMAGES.Maria_Ocean_Fish_in_gravy,
    ratings: [1,2,3,4,5],
    title: 'Maria Ocean Fish in gravy',
    subTitle: 'Maria',
    oldPrice: 600,
    size: [{
      id:1,
      price: 75.00,
      weight: '70 gm Pouch' 
    },
  ],
    description: 'MARIA Premium Kitten Wet Food with Ocean Fish in Gravy for Cats (1-12 Month) for All Breeds with Vet Certified and Palatability - Pack of 12 (70 gm Pouch). Recommended for Kittens of all breeds. This recipe has high protein and fat in the ratio – Protein: 11%, Fat: 1.5%, Fiber: 8.5 %, Moisture: 9% which gives high energy for working cats and good growth for kitten.',
    brandName: 'Maria',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c11',
    img: IMAGES.Maria_Chicken_and_Salmon_in_grav,
    ratings: [1,2,3,4,5],
    title: 'Maria Chicken and Salmon in gravy',
    subTitle: 'Maria',
    oldPrice: 600,
    size: [{
      id:1,
      price: 75.00,
      weight: '70 gm Pouch' 
    },
  ],
    description: 'MARIA Premium Kitten Wet Food with Chicken and Salmon in Gravy for Cats (1-12 Month) for All Breeds with Vet Certified and Palatability - Pack of 4 (70gm Pouch). Recommended for Kittens of all breeds. This recipe has high protein and fat in the ratio – Protein: 11%, Fat: 1.5%, Fiber: 8.5 %, Moisture: 9% which gives high energy for working cats and good growth for kitten.',
    brandName: 'Maria',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c12',
    img: IMAGES.Petideal_Creamy_Chicken_Scallo,
    ratings: [1,2,3,4,5],
    title: 'Petideal Creamy Chicken & Scallop',
    subTitle: 'Petideal',
    oldPrice: 600,
    size: [{
      id:1,
      price: 90.00,
      weight: '60 gms' 
    },
  ],
    description: 'Outstanding odour control. Has very high liquid absorption rate. Clumps quickly. Clumping cat litter.',
    brandName: 'Petideal',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c14',
    img: IMAGES.Petideal_Creamy_Salmon,
    ratings: [1,2,3,4,5],
    title: 'Petideal Creamy Salmon',
    subTitle: 'Petideal',
    oldPrice: 600,
    size: [{
      id:1,
      price: 90.00,
      weight: '60 gms'  
    },
  ],
    description: 'Creamy treat For cat. Best Treat For Cat. Healthy Food. Delicious Taste.',
    brandName: 'Petideal',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c15',
    img: IMAGES.Petideal_Creamy_Chicken_Shrimp,
    ratings: [1,2,3,4,5],
    title: 'Petideal Creamy Chicken & Shrimp',
    subTitle: 'Petideal',
    oldPrice: 600,
    size: [{
      id:1,
      price: 90.00,
      weight: '60 gms' 
    },
  ],
    description: 'Creamy treat For cat. Best Treat For Cat. Healthy Food. Delicious Taste.',
    brandName: 'Petideal',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c16',
    img: IMAGES.Petideal_Creamy_Tuna,
    ratings: [1,2,3,4,5],
    title: 'Petideal Creamy Tuna',
    subTitle: 'Petideal',
    oldPrice: 600,
    size: [{
      id:1,
      price: 90.00,
      weight: '60 gms' 
    },
  ],
    description: 'Creamy treat For cat. Best Treat For Cat. Healthy Food. Delicious Taste.',
    brandName: 'Petideal',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c17',
    img: IMAGES.Ginger_Tom_Fresh_Lamb,
    ratings: [1,2,3,4,5],
    title: 'Ginger Tom Fresh Lamb',
    subTitle: 'Butch',
    oldPrice: 600,
    size: [{
      id:1,
      price: 520.00,
      weight: '600 gms' 
    },
  ],
    description: 'Ginger Tom is a moist, meaty cat food made of fresh lamb and beef. Enriched with Taurine for a healthy heart and eyes, Omega-3 (derived from fish) for a soft, shiny coat and supple joints, Ginger Tom is nutritionally complete for your Kitten, Adult or Senior Cat.',
    brandName: 'Butch',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c18',
    img: IMAGES.Ginger_Tom_with_Whole_Fish,
    ratings: [1,2,3,4,5],
    title: 'Ginger Tom with Whole Fish',
    subTitle: 'Butch',
    oldPrice: 600,
    size: [{
      id:1,
      price: 520.00,
      weight: '600 gms' 
    },
  ],
    description: 'Ginger Tom is a moist, meaty cat food made of fresh lamb and beef. Enriched with Taurine for a healthy heart and eyes, Omega-3 (derived from fish) for a soft, shiny coat and supple joints, Ginger Tom is nutritionally complete for your Kitten, Adult or Senior Cat.',
    brandName: 'Butch',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   },

   {
    id: 'c19',
    img: IMAGES.Butch_Blue_Label_Dog_and_Cat_Foo,
    ratings: [1,2,3,4,5],
    title: 'Butch Blue Label Dog and Cat Food',
    subTitle: 'Butch',
    oldPrice: 600,
    size: [{
      id:1,
      price: 690.00,
      weight: '800 gms' 
    },
  ],
    description: 'Butch Blue Label, made with whole fish and highly digestible fresh chicken contains no soy making it a delicious meal for both your cat and dog. Blue Label is a moisture rich food scientifically formulated with the correct proportion of vitamins, minerals and nutrients for your growing puppy, adult, senior dog, kitten, adult or senior cat.',
    brandName: 'Butch',
    category: 'cats',
    contact: 9845195449,
    related: ['c2', 'c3']
   }, 

];

export const smallPetCategoryItems = [
   {
    id: 'sp1',
    img: IMAGES.Smart_Heart_Rabbit_Food_Apple,
    ratings: [1,2,3,4,5],
    title: 'Smart Heart Rabbit Food Apple ',
    subTitle: 'Seeds',
    oldPrice: 600,
    size: [{
      id:1,
      price: 345.00,
      weight: '01 kg' 
    },
  ],
    description: 'Package contents: 1 Smart Heart rabbit food apple. 100 Percent natural. No artificial additives, colours and preservatives. High quality digestible fibre for better gastro intestinal health. Sealed to maintain quality and nutrients.',
    brandName: 'Smart Heart',
    category: 'small pets',
    contact: 9845195449,
    related: ['sp2', 'sp3']
   },
   {
    id: 'sp2',
    img: IMAGES.Smart_Heart_Rabbit_Food_Veggies_Cereals,
    ratings: [1,2,3,4,5],
    title: 'Smart Heart Rabbit Food Veggies & Cereals',
    subTitle: 'Pellet',
    oldPrice: 600,
    size: [{
      id:1,
      price: 345.00,
      weight: '01 kg' 
    },
  ],
    description: 'Package contents: 1 Smart Heart rabbit food apple. 100 Percent natural. No artificial additives, colours and preservatives. High quality digestible fibre for better gastro intestinal health. Sealed to maintain quality and nutrients.',
    brandName: 'Smart Heart',
    category: 'small pets',
    contact: 9845195449,
    related: ['sp2', 'sp3']
   },
   {
    id: 'sp3',
    img: IMAGES.Smart_Heart_Rabbit_Food_Wildberry,
    ratings: [1,2,3,4,5],
    title: 'Smart Heart Rabbit Food Wildberry',
    subTitle: 'Seeds',
    oldPrice: 600,
    size: [{
      id:1,
      price: 345.00,
      weight: '01 kg' 
    },
  ],
    description: 'Package contents: 1 Smart Heart rabbit food apple. 100 Percent natural. No artificial additives, colours and preservatives. High quality digestible fibre for better gastro intestinal health. Sealed to maintain quality and nutrients.',
    brandName: 'Smart Heart',
    category: 'small pets',
    contact: 9845195449,
    related: ['sp2', 'sp3']
   },
   {
    id: 'sp4',
    img: IMAGES.Smart_Heart_Rabbit_Food_Raspberry,
    ratings: [1,2,3,4,5],
    title: 'Smart Heart Rabbit Food Raspberry',
    subTitle: 'Dry',
    oldPrice: 600,
    size: [{
      id:1,
      price: 345.00,
      weight: '01 kg' 
    },
    {
      id:2,
      price: 825.00,
      weight: '03 kg' 
    },
  ],
    description: 'Package contents: 1 Smart Heart rabbit food apple. 100 Percent natural. No artificial additives, colours and preservatives. High quality digestible fibre for better gastro intestinal health. Sealed to maintain quality and nutrients.',
    brandName: 'Smart Heart',
    category: 'small pets',
    contact: 9845195449,
    related: ['sp2', 'sp3']
   },
   {
    id: 'sp5',
    img: IMAGES.Smart_Heart_Hamster_Food,
    ratings: [1,2,3,4,5],
    title: 'Smart Heart Hamster Food',
    subTitle: 'Dry',
    oldPrice: 600,
    size: [{
      id:1,
      price: 190.00,
      weight: '300 gms' 
    },
  ],
    description: 'Package contents: 1 Smart Heart rabbit food apple. 100 Percent natural. No artificial additives, colours and preservatives. High quality digestible fibre for better gastro intestinal health. Sealed to maintain quality and nutrients.',
    brandName: 'Smart Heart',
    category: 'small pets',
    contact: 9845195449,
    related: ['sp2', 'sp3']
   },
];

export const dogCategoryItems = [
  {
    id: "d1",
    img: IMAGES.Smart_Heart_Puppy_Dog_Food,
    ratings: [1, 2, 3, 4, 5],
    title: "Smart Heart Puppy Dog Food ",
    subTitle: "Dry Power Pack",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 375.00,
        weight: "1 Kgs",
      },
      {
        id: 2,
        price: 1005.00,
        weight: "3 Kgs",
      },
      {
        id: 1,
        price: 2865.00,
        weight: "10 Kgs",
      },
      {
        id: 1,
        price: 5055.00,
        weight: "20 Kgs",
      },
    ],
    description:"Smart Heart Puppy Food Power Pack is a properly nourished meal for puppies of age between 3-15 months of all breeds. It contains L-arginine and L-cartinine which enhances muscle mass and hence, helps in maintaining weight of your puppy. Smart Heart Puppy Power Pack consists of Chicken, Egg and Beet root pulp which provides 32% protein content and 4% dietary fiber.",
    category: "dogs",
    brandName: "Smart Heart",
    contact: 9845195449,
    related: ["d2", "d3"],
  },
  {
    id: "d2",
    img: IMAGES.Smart_Heart_Adult_Dog_Food,
    ratings: [1, 2, 3, 4, 5],
    title: "Smart Heart Adult Dog Food ",
    subTitle: "Dry Power Pack",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 370.00,
        weight: "1 Kgs",
      },
      {
        id: 2,
        price: 995.00,
        weight: "3 Kgs",
      },
      {
        id: 3,
        price: 2675.00,
        weight: "10 Kgs",
      },
      {
        id: 4,
        price: 4845.00,
        weight: "20 Kgs",
      },
    ],
    description: `Enhanced brain function: dha (from fish oil) and choline (from lecithin) for enhanced brain and nervous system function. 
Healthy heart: omega-3 fatty acids from fish oil for a healthy heart. Healthy digestion: highly digestible ingredients for improved digestion and stool kind. 
Immune system: vitamin e and selenium for strong immune system`,
    category: "dogs",
    brandName: "Smart Heart",
    contact: 9845195449,
    related: ["d1", "d3"],
  },
  {
    id: "d3",
    img: IMAGES.Goat_Milk_Powder,
    ratings: [1, 2, 3, 4, 5],
    title: "Goat Milk Powder",
    subTitle: "Goat Gold",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 990.00,
        weight: "200 gms",
      },
    ],
    description: `Puppy and mother dog breastfeeding. Made from goat milk
Suitable for all breeds of dogs and easy brew`,
    category: "dogs",
    brandName: "Goat Gold",
    contact: 9845195449,
    related: ["d1", "d2"],
  },
  {
    id: "d4",
    img: IMAGES.Happy_Bites_Dog_Biscuits,
    ratings: [1, 2, 3, 4, 5],
    title: "Happy Bites - Dog BiscuitsGoat Milk Powder",
    subTitle: "Happy Bites",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 225.00,
        weight: "500 gms",
      },
    ],
    description: `Delightfully crunchy dog treats for your pet. With the goodness of protein and a source of calcium. High Bio Availablity oF Proteien. All natural ingredients and oven baked biscuits`,
    category: "dogs",
    brandName: "Happy Bites",
    contact: 9845195449,
    related: ["d1", "d3"],
  },

  {
    id: 'd5',
    img: IMAGES.VETO_Adult_Dry_Dog_Food,
    ratings: [1,2,3,4,5],
    title: 'VETO Adult Dry Dog Food',
    subTitle: 'Veto',
    oldPrice: 600,
    size: [{
      id:1,
      price: 1800.00,
      weight: '10 Kgs' 
    },
  ],
    description: 'VETO Adult Dry Dog Food / Pet Food for Active Dogs for All Breeds - Chicken and Liver Flavor ( 10 kg Pack). Recommended for adult dogs of all breeds. This recipe has high protein and fat in the ratio – Protein: 18%, Fat: 7%, Fiber: 8.5 %, Moisture: 9% which gives high energy for working dogs and good growth for puppies.',
    brandName: 'Veto',
    category: 'dogs',
    contact: 9845195449,
    related: ['d2', 'd3']
   },

   {
    id: 'd6',
    img: IMAGES.Butch_Blue_Label_Dog_and_Cat_Foo,
    ratings: [1,2,3,4,5],
    title: 'Butch Blue Label Dog and Cat Food',
    subTitle: 'Butch',
    oldPrice: 600,
    size: [{
      id:1,
      price: 690.00,
      weight: '800 gms' 
    },
  ],
    description: 'Butch Blue Label, made with whole fish and highly digestible fresh chicken contains no soy making it a delicious meal for both your cat and dog. Blue Label is a moisture rich food scientifically formulated with the correct proportion of vitamins, minerals and nutrients for your growing puppy, adult, senior dog, kitten, adult or senior cat.',
    brandName: 'Butch',
    category: 'dogs',
    contact: 9845195449,
    related: ['d2', 'd3']
   },

   {
    id: 'd7',
    img: IMAGES.Butch_Blue_Label_Dog_Food,
    ratings: [1,2,3,4,5],
    title: 'Butch Blue Label Dog Food',
    subTitle: 'Butch',
    oldPrice: 600,
    size: [{
      id:1,
      price: 690.00,
      weight: '800 gms' 
    },
  ],
    description: 'Butch White Label, made with 100% highly digestible fresh chicken contains no red meat which makes it an excellent choice for your red meat intolerant or sensitive dog. White Label is a moisture rich food scientifically formulated with the correct proportion of vitamins, minerals and nutrients for your growing puppy, adult or senior dog.',
    brandName: 'Butch',
    category: 'dogs',
    contact: 9845195449,
    related: ['d2', 'd3']
   },

];

export const birdCategoryItems = [
  {
    id: "b1",
    img: IMAGES.Smart_Heart_Bird_Food_Budgies,
    ratings: [1, 2, 3, 4, 5],
    title: "Smart Heart Bird Food Budgies",
    subTitle: "Budgies",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 395.00,
        weight: "1 Kg",
      },
    ],
    description: `Package contents: 1 Smart Heart bird food budgies. Omega 3 and omega 6 fatty acid to improve brain function. Vitamin e to aid the productive system. Vitamin a to improve visual acuity. Extruded kibbles for high digestibility and palatability. Vitamin b1 and b2 to nourish nervous system`,
    category: "birds",
    brandName: "Smart Heart",
    contact: 9845195449,
    related: ["b2", "b3"],
  },
];

export const fishCategoryItems = [
  {
    id: "f1",
    img: IMAGES.Porpoise_Goldfish_Food_Pellet,
    ratings: [1, 2, 3, 4, 5],
    title: "Porpoise Goldfish Food Pellet",
    subTitle: "Pellet",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 65.00,
        weight: "88 gms",
      },
    ],
    description: `Shelf life : 18 months. Feeding method : Feed 2-3 times daily, as much as your fish can consume within 5 minutes. Advantage: Easily Digestible; Processing Method : Extrusion. Storage : Indoor temperature, sealed, dry, pay attention to moisture.`,
    category: "fish",
    brandName: "Porpoise",
    contact: 9845195449,
    related: ["f2", "f3"],
  },
  {
    id: "f2",
    img: IMAGES.Porpoise_Tropical_Fish_Food_Pellet,
    ratings: [1, 2, 3, 4, 5],
    title: "Porpoise Tropical Fish Food Pellet",
    subTitle: "Pellet",
    oldPrice: 600,
    size: [
      {
        id: 1,
        price: 90.00,
        weight: "88 gms",
      },
    ],
    description: `Shelf life : 18 months. Feeding method : Feed 2-3 times daily, as much as your fish can consume within 5 minutes. Advantage: Easily Digestible; Processing Method : Extrusion. Storage : Indoor temperature, sealed, dry, pay attention to moisture.`,
    category: "fish",
    brandName: "Porpoise",
    contact: 9845195449,
    related: ["f2", "f1"],
  },
];

export const categories = [
  {
    id: "cats",
    title: "cat",
    categoryItems: catCategoryItems,
    desc: 'up to 15% off',
    img: IMAGES.CATS, 
  },
  {
    id: "dogs",
    title: "dog",
    categoryItems: dogCategoryItems,
    desc: 'up to 10% off',
    img: IMAGES.DOGS,
  },
  {
    id: "birds",
    title: "birds",
    categoryItems: birdCategoryItems,
    desc: 'up to 15% off',
    img: IMAGES.BIRDS,
  },
  {
    id: "fish",
    title: "fish",
    categoryItems: fishCategoryItems,
    desc: 'up to 10% off',
    img: IMAGES.FISH,
  },
  {
    id: "small pets",
    title: "small pets",
    categoryItems: smallPetCategoryItems,
    desc: 'up to 10% off',
    img: IMAGES.SMALL_PETS,
  },
];
