import React from "react";
import style from "./orderingNumber.module.css";

const OrderingNumber = () => {
  return (
    <>
      <div className={style.container}>
        <p>For Ordering Online Call us on </p>
        <div className={style.numberWrap}>
          <a href="tel:9845195449">+91 98451 95449</a>
          <span>/</span>
          <a href="tel:08041695449">080 4169 5449</a>
        </div>
      </div>
    </>
  );
};

export default OrderingNumber;
