import React from 'react'
import styles from './profileCard.module.css'
import Button from '../Button'


function ProfileCard({item, goTo, btnText }) {
 const {img, title, desc, id, contact } = item
 return (
  <div className={styles.card}>
   <img src={img} alt='alt' className={styles.profile_image}/>
   <h3>{title}</h3>
   {/* <p>{desc}</p> */}
   <Button text={btnText} goTo={goTo} id={id} href={contact}/>
  </div>
 )
}

export default ProfileCard
