import React from 'react';
import {IMAGES} from '../../../assests';
import styles from './testimonies.module.css';

function index({image, name, testimony, location}) {
 return (
  <div className={styles.testimony_container}>
    <img src={image} alt="faizan" className={styles.testimonial_image}/> <br />
          <img src={IMAGES.QOUTES} alt="QOUTES" className={styles.testimonial_quotes}/>
          <p className={styles.testimony}>
         {testimony}
          </p>
          <p className={styles.text}>{name}</p>
          <p className={styles.text}>{location}</p>
  </div>
 )
}

export default index
