import React from "react";
import style from "./landingBg.module.css";

const HeroBg = () => {
  return (
    <>
      <div className={style.heroContentWrap}>
        <p className={style.heroContent}>
          Product <span>List</span>
        </p>
      </div>
    </>
  );
};

export default HeroBg;
