
function Validate(values) {
 const errors = {};
 if(!values.name){
    errors.name = 'Name required'
 } 

 if(!values.email){
    errors.email = 'Email required'
 } else if (!/\S+@\S+\.\S+/.test(values.email)){
    errors.email = 'Email address is invalid'
 }

 if(values.number === '' && values?.number?.length !== 10){
    errors.number = 'Please enter 10 digit mobile number'
 }
 
 if(!values.message){
    errors.message = 'Message is required'
 }
 
 if(!values.isChecked){
    errors.isChecked = 'Please select the check box'
 } 

 return errors

}

export default Validate
