import React from 'react'
import style from "./eventCard.module.css";

function EventCard({ data }) {
  return (
    <>
      <div className={style.container}>
        <h2>
          <span>News</span> & Events
        </h2>
        {data.map((item) => {
          const { img, title, desc, date } = item;
          return (
            <div className={style.card}>
              <div>
                <img src={img} alt="event" className={style.image} />
              </div>
              <div className={style.detail}>
                <p className={style.title}>{title}</p>
                <p className={style.desc}>{desc}</p>
                <p className={style.date}>{date}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default EventCard
