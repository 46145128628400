import React from "react";
import style from "./productDetail.module.css";
import Button from "../../utilities/Button/index";
import { IMAGES } from "../../../assests/index";

const ProductDetail = ({ increment, decrement, count, product }) => {
  const {
    img,
    ratings,
    title,
    // newPrice,
    description,
    // productId,
    subTitle,
    size,
    contact
  } = product;

  return (
    <>
      <div className={style.container}>
        <div className={style.imgWrap}>
          <img src={img} alt="" />
        </div>
        <div className={style.contentWrap}>
          <p className={style.cardHeader}>{title} </p>
          <p className={style.cardSubHeader}>{subTitle}</p>
          <div className={style.starWrap}>
            {ratings.map((item) => (
              <img src={IMAGES.STAR5} alt="" />
            ))}
          </div>
          <div className={style.sizeWrap}>
            {size.map((item) => {
              const { id, price, weight } = item;
              return (
                <div key={id} className={style.sizeBorderWrap}>
                  <p className={style.sizeContent}>
                    Size: <span>{weight}</span>
                  </p>
                  <p className={style.priceContent}>₹{price} </p>
                </div>
              );
            })}
          </div>
          {/* <p className={style.price}> {newPrice} </p> */}
          <div className={style.contentDescWrap}>
            <p className={style.contentDesc}>{description}</p>
          </div>
          <div className={style.qtyBtnWrap}>
            <div className={style.qtyWrap}>
              <p className={style.qty}>QTY</p>
              <div className={style.qtyNumWrap}>
                <button onClick={decrement} type="submit">
                  -
                </button>
                <p>{count} </p>
                <button onClick={increment} type="submit">
                  +
                </button>
              </div>
            </div>
            <Button href={contact} text="Call to Purchase" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
