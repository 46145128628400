/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import HeroBg from "../../components/utilities/HeroBg";
import OrderingNumber from "../../components/utilities/OrderingNumber";
import style from "./shop.module.css";
import { categories, smallPetCategoryItems, catCategoryItems, dogCategoryItems, fishCategoryItems, birdCategoryItems } from '../../data'
import ProductCard from '../../components/utilities/ProductCard'
import Pagination from "../../components/utilities/Pagination";
import PageSearch from "../../components/shop/PageSearch";
import { Link } from "react-router-dom";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

const Shop = () => {
  const history = useHistory();
  let allCategories= [];
  categories.forEach(item => {
    allCategories = [...allCategories, ...item.categoryItems]
  })
  const [products, setProducts] = useState(allCategories);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('All')
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(12);
  const productCategories = ['All', ...new Set(allCategories.map(item => item.category))]
  const brands = [...new Set(allCategories.map(item => item.brandName))]
  const [term, setTerm] = useState('');
useEffect(() => {
  if (history.location.pathname.includes("dog")) {
    setProducts(dogCategoryItems);
  } else if (history.location.pathname.includes("cat")) {
    setProducts(catCategoryItems);
  } else if (history.location.pathname.includes("birds")) {
    setProducts(birdCategoryItems);
  } else if (history.location.pathname.includes("small pets")) {
    setProducts(smallPetCategoryItems);
  } else if (history.location.pathname.includes("fish")) {
    setProducts(fishCategoryItems);
  } else {
    setProducts(allCategories);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [history.location.pathname]);

  const indexOfLast = currentPage * productsPerPage;
  const indexOfFirst = indexOfLast - productsPerPage;
  const currentProducts = products.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const getProductsByCategory = (category) => {
    const items = allCategories.filter((item) => item.category === category);
    setProducts(items);
    if(category === 'All') {
      history.push('/shop')
      setProducts(allCategories)
    } else {
      history.push(`/shop/${category}`)
      setCategory(items)
    }
    setBrand('')
    setCurrentPage(1);
  };

  const getProductsByBrand = (categoryItem) => {
    const items = allCategories.filter((item) => {
      if(category === 'All' || category === '') {
        return item.brandName === categoryItem
      }
      setBrand(categoryItem)
      return item.brandName === categoryItem && item.category === category
    });
    setProducts(items);
    setCurrentPage(1);
  };

  const productPath = (id) => {
    history.push(`/product/${id}`);
  };

  const handleTerm = (e) => setTerm(e.target.value)

  const onSubmit = () => {
    const filteredData = products.filter(item => (item.title.toLowerCase().includes(term)));
    setProducts(filteredData)
  }

  return (
    <>
      <MainContainer className={style.container}>
        {/* <Header /> */}
        <div className={style.heroBg}>
          <HeroBg />
        </div>
        <OrderingNumber />
        <div className={style.pagesearchWrap}>
          <PageSearch
            indexOfFirst={indexOfFirst}
            indexOfLast={indexOfLast}
            length={products.length}
            term={term}
            handleTerm={handleTerm}
            onSubmit={onSubmit}
          />
        </div>
        <section className={style.products}>
          <section className={style.product_list}>
            {/* <p>{indexOfFirst + 1} - {indexOfLast > products.length ? products.length : indexOfLast} of {products.length}</p> */}
            <div className={style.categoryNameWrap}>
              <p>Category: {history.location.pathname.substring(6) || "All"}</p>
            </div>
            {products.length > 0 ? (
              <>
                <div className={style.list}>
                  {currentProducts.map((item) => {
                    return (
                      <Link to={`/product/${item.id}`} key={item.id}>
                        <ProductCard item={item} btnText="Shop Now" goTo={productPath}/>
                      </Link>
                    );
                  })}
                </div>
                <Pagination
                  itemsPerPage={productsPerPage}
                  totalItems={products.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              </>
            ) : (
              <p className={style.noProducts}>No products found</p>
            )}
          </section>
          <section className={style.categoriesWrap}>
            <div className={style.category_section}>
              <p className={style.heading}>Product Categories</p>
              <div className={style.underline}></div>
              <ul>
                {productCategories.map((item, index) => (
                  <li
                    key={item}
                    className={item===category ? style.list_item_selected : style.list_item}
                    onClick={() => {
                      getProductsByCategory(item)
                      setCategory(item)
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className={style.category_section}>
              <p className={style.heading}>Brands</p>
              <div className={style.underline}></div>
              <ul>
                {brands.map((item) => (
                  <li
                    key={item}
                    className={item===brand ? style.list_item_selected : style.list_item}
                    onClick={() => getProductsByBrand(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </section>
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
};

export default Shop;
