import React from "react";
import { IMAGES } from "../../../assests";
import AddressCard from "../AddressCard";
import style from "./contactAddress.module.css";

const ContactAddress = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.imgWrap}>
          <a
            href="https://goo.gl/maps/TjU5vpVSu9pDZrft9"
            target="_blank"
            rel="noreferrer"
          >
            <img src={IMAGES.MAP} alt="" />
          </a>
        </div>
        <div>
          <AddressCard />
        </div>
      </div>
    </>
  );
};

export default ContactAddress;
