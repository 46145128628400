import React from "react";
import { useHistory } from "react-router-dom";
import { IMAGES } from "../../../assests";
import Search from "../../utilities/Search";
import style from "./backSearch.module.css";

const BackSearch = () => {
  const history = useHistory();
  const goToPreviousPath = () => {
    history.goBack()
}
  return (
    <>
      <div className={style.container}>
        <div className={style.backPageWrap}>
            <div className={style.linkWrap} onClick={goToPreviousPath}>
              <img src={IMAGES.ORANGE_ARROW_LEFT} alt="" />
              <span>Back</span>
            </div>
        </div>
        <Search />
      </div>
    </>
  );
};

export default BackSearch;
