import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import BackSearch from "../../components/ProductDesc/BackSearch";
import ProductDetail from "../../components/ProductDesc/ProductDetail";
import RelatedProduct from "../../components/ProductDesc/RelatedProduct";
// import ReviewComment from "../../components/ProductDesc/Review Comment";
// import ReviewForm from "../../components/ProductDesc/Review Form";
import HeroBg from "../../components/utilities/HeroBg";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";
import OrderingNumber from "../../components/utilities/OrderingNumber";
import { categories } from "../../data";
import style from "./productDesc.module.css";

const ProductDesc = () => {
  const [count, setCount] = useState(1);
  const history = useHistory();
  let data= [];
  categories.forEach(item => {
    data = [...data, ...item.categoryItems]
  })
  const category = [...new Set(data.map((item) => item.category))];
  // const brands = [...new Set(data.map((item) => item.brandName))];

  const { id } = useParams();
  const product = data.find((item) => item.id === id);
  const relatedProduct = data.filter(
    (item) => item.category === product.category && item.id !== product.id
  );

  const increment = () => {
    setCount(count + 1);
  };

  let decrement = () => {
    setCount(count - 1);
  };

  if (count > 0) {
    decrement = () => setCount(1);
  }

  const goTo = (item) => {
    history.push(`/shop/${item}`);
  }

  return (
    <>
      <MainContainer className={style.container}>
        {/* <Header /> */}
        <div className={style.heroBg}>
          <HeroBg />
        </div>
        <OrderingNumber />
        <BackSearch />
        <div className={style.productContainer}>
          <div className={style.productReviewWrap}>
            <ProductDetail
              increment={increment}
              decrement={decrement}
              count={count}
              product={product}
            />
            <RelatedProduct relatedProduct={relatedProduct} />
            {/* <ReviewComment /> */}
            {/* <ReviewForm /> */}
          </div>
          <div className={style.categoryWrap}>
            <div className={style.category_section}>
              <p className={style.heading}>Product Categories</p>
              <div className={style.underline}></div>
              <ul>
                {category.map((item) => (
                  <li
                    key={item}
                    className={style.list_item}
                    onClick={() => goTo(item)
                    }
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className={style.category_section}>
              <p className={style.heading}>Brands</p>
              <div className={style.underline}></div>
              <ul>
                {brands.map((item) => (
                  <li
                    key={item}
                    className={style.list_item}
                    // onClick={() => getProductsByBrand(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
};

export default ProductDesc;
