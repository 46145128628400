import React, { useState } from "react";
import emailjs from 'emailjs-com';  
import { IMAGES } from "../../../assests";
import Button from "../../utilities/Button/index";
import Validate from "../../utilities/Validate";
import style from "./contactForm.module.css";
import styles from '../../utilities/Button/button.module.css'

const ContactForm = () => {
  const [contact, setContact] = useState({ 
    name: '',
    number: '',
    email: '',
    message:'',
    isChecked: true,
   })
   const [errors, setErrors] = useState({}) 

   const onChange =  (e) => {
     setContact( {...contact, [e.target.name]:e.target.value})
     if(e.target.name === 'check') {
      setContact({...contact, isChecked: !contact.isChecked})
     }
     }
  const sendEmail = (e) => {
    let errors = Validate(contact)
    setErrors(errors)
    e.preventDefault();
    if(Object.keys(errors).length === 0) {
      emailjs.sendForm('service_obpyzfk', 'template_gu5icub', e.target, 'user_5S4BTvf4deXBJNINtUzGz')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setContact({ 
        name: '',
        number: '',
        email: '',
        message:'',
        isChecked: true,
       })
      window.alert('Your requsted submitted successfully')
    }
    if(errors?.isChecked){
      window.alert('Please select the checkbox')
    }
  };
  return (
    <>
      <div className={style.container}>
        <p className={style.formHeader}>Contact us</p>
        <form className={style.formWrap} onSubmit={sendEmail}>
          <div className={style.inputWrap}>
          <div className={style.label}>
          <label htmlFor='name'>
              Name<span>*</span>
            </label>
            {errors?.name && <p>{errors.name}</p>}
            </div>
            <input type="text" id='name' name='name' value={contact.name} onChange={onChange}/>
          </div>
          <div className={style.inputWrap}>
            <div>
            <label htmlFor='number'>
              Phone Number<span>*</span>
            </label>
            {errors?.number && <p>{errors.number}</p>}
            </div>
            <input type="text" id='number' name='number' value={contact.number} onChange={onChange}/>
          </div>
          <div className={style.inputWrap}>
          <div>
          <label htmlFor='email'>
              Email<span>*</span>
            </label>
            {errors?.email && <p>{errors.email}</p>}
            </div>
            <input type="email" id='email' name='email' value={contact.email} onChange={onChange}/>
          </div>
          <div className={style.inputWrap}>
            <label htmlFor='message'>Your Message</label>
            <textarea cols="30" rows="4" name='message' id='message' value={contact.message} onChange={onChange}/>
          </div>
          <div className={style.checkboxWrap}>
            <input type="checkbox" name='check' defaultChecked={contact.isChecked} onClick={onChange}/>
            <span>
              By submitting this form, I agree to receive communications from
              MultiMark in connection with my enquiry.
            </span>
          </div>
          {/* <input type="submit" value='Send'/> */}
          <button className={styles.btn} onSubmit={sendEmail}> Submit </button>
        </form>
        <div className={style.imgWrap}>
          <img src={IMAGES.GREYBG_RIGHT} alt="" />
        </div>
      </div>
    </>
  );
};

export default ContactForm;
