import React from "react";
import style from "./bannerBgContent.module.css";

const BannerBgContent = ({ contentData }) => {
  const {
    contentHeadSpan,
    contentHead,
    contentDesc,
    contentImg,
    contentImgData,
  } = contentData;

  return (
    <>
      <div className={style.container}>
        <div className={style.contentWrap}>
          <p className={style.content}>
            <span>{contentHeadSpan}</span>
            <br />
            {contentHead}
          </p>
          <p className={style.contentDesc}>{contentDesc}</p>
        </div>
        <div className={style.ImgWrap}>
          <img src={contentImg} alt="" />
          <div className={style.imgContent}>
            <span>{contentImgData}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerBgContent;
