import React from "react";
import { IMAGES } from "../../assests";
import style from "./aboutUs.module.css";
// import Header from "../../components/Header/index";
// import Footer from "../../components/Footer/index";
import WhoAreWe from "../../components/AboutUs/WhoAreWe";
import OurMission from "../../components/AboutUs/OurMission";
// import OurTeam from "../../components/AboutUs/OurTeam";
import AboutBannerContent from "../../components/AboutUs/AboutBannerContent";
import OurCommunity from "../../components/AboutUs/Our Community";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

const data = [
  {
    id: 1,
    title: "Mark your Success",
    desc: " Our first and foremost principle is mutual success and growth. We believe that no partnership or community can exist and flourish without growth and success. Hence we dream of growing together. Let’s mark our success together.",
    img: IMAGES.CUP,
  },
  {
    id: 2,
    title: "Customer Satisfaction",
    desc: "  Customer satisfaction is an integral part of our business. The ultimate aim is to maintain our good relationship with our customers after using our services and products. The customer is our boss, quality is our work and value for money is our goal.",
    img: IMAGES.CUSTOMER,
  },
  {
    id: 3,
    title: "Mutual trust and Integrity",
    desc: " Our faith in quality and dedication has elevated us to the position we are today. And we promise to stick to our chosen path by following professional ethics and mutual trust. As we focus ahead we look to build and maintain strong long term relationship with business partners based on mutual trust, integrity and professional ethics.",
    img: IMAGES.HEART,
  },
  {
    id: 4,
    title: "Quality is our work",
    desc: "  Multimark India Group is dedicated to the biggest quality providing top quality products is our passion. Quality is the uncompromising standard for our actions. From all our accredited credentials, we are able to guarantee that quality ideals are strictly followed. We only bring the best quality products so that our customers can get product excellence.",
    img: IMAGES.REWARD,
  },
];

const team = [
  {
    id: 1,
    title: "John Doe",
    desc: "CEO & Founder",
    img: IMAGES.JOHN1,
    contact: 9845195449,
  },
  {
    id: 2,
    title: "Jane",
    desc: "Sales Head",
    img: IMAGES.JANE,
    contact: 9845195449,
  },
  {
    id: 3,
    title: "John Doe",
    desc: "Marketing Head",
    img: IMAGES.JOHN2,
    contact: 9845195449,
  },
  {
    id: 4,
    title: "Sean Adam",
    desc: "Marketing Head",
    img: IMAGES.SEAN,
    contact: 9845195449,
  },
  {
    id: 5,
    title: "Anna John",
    desc: "Sales Executive",
    img: IMAGES.ANNA,
    contact: 9845195449,
  },
];

const questions = [
  {
    id: 1,
    question: "Products for all varieties of pets",
    answer:
      "At Multimark India Group its our constant endeavour to carefully stock everything you could possibly need to keep your beloved pet happy, healthy and safe. Whether it is your Darling Dog or your Cute Cat or your Magnificent Macaw or your Radiant Rabbit or your Happy Hamster or even your Fancy Fish, we pamper them all.",
  },
  {
    id: 2,
    question: "Finest brands and a bond of nutrition",
    answer:
      "Our range of pet products and services includes some of the finest brands in the world and our network across the country ensures effective transit from port to pet!!  We have a diverse portfolio of delightful foods, tasty treats, nutritious food supplements and some gorgeous grooming products.",
  },
  {
    id: 3,
    question: "Long term relationship with partners",
    answer:
      "Our rich experience ability and excellent service has given us an opportunity to deal with various leading companies. Every company we work with gets special importance and commitment as we focus on building a long term relationship with both our business partners and our customers based on mutual trust, integrity and professional ethics.",
  },
];

const AboutUs = () => {
  return (
    <>
      <MainContainer className={style.container}>
        {/* <Header /> */}
        <div className={style.bannerBg}>
          <AboutBannerContent />
        </div>
        <WhoAreWe questions={questions} />
        <OurMission data={data} />
        {/* <OurTeam team={team} /> */}
        <OurCommunity />
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
};

export default AboutUs;
