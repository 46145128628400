import React from "react";
import { IMAGES } from "../../../assests";
import AboutUs from "../AboutUs";
import style from "./Hero.module.css";

const Hero = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.contentWrap}>
          <p className={style.heroContent}>
            Leaders in providing{" "}
            <span>
              Healthy <br /> Food
            </span>{" "}
            for your Loving pets
          </p>
          <div className={style.boneImg}>
            <img src={IMAGES.BONE_HERO} alt="" />
            <div className={style.boneContent}>
              <span>Shop Now</span>
            </div>
          </div>
        </div>
        <div className={style.imgVectorMobileWrap}>
          <img src={IMAGES.VECTOR_MOBILE} alt="" />
        </div>
        <div className={style.imgVectorDesktopWrap}>
          <img src={IMAGES.VECTOR_DESKTOP} alt="" />
        </div>
        <div className={style.imgDogWrap}>
          <img src={IMAGES.DOGS_AMOEBA} alt="" />
        </div>
      </div>
        <AboutUs />
    </>
  );
};

export default Hero;
