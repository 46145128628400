import React from "react";
import MissionCard from "../MissionCard";
import style from "./ourMission.module.css";

const OurMission = ({ data }) => {
  return (
    <>
      <div className={style.container}>
        <p className={style.quouteMob}>
          <span>We dream</span> of growing together <br /> Together lets <br />
          <span>‘Mark our Success’!!!</span>
        </p>
        <p className={style.quouteDesk}>
          <span>We dream</span> of growing together <br /> Together lets{" "}
          <span> ‘Mark our Success’!!!</span>
        </p>
        <div className={style.ourMissonContentWrap}>
          <h2>
            Our <span>Mission</span>
          </h2>
          <p className={style.ourMissonContent}>
            We take pride in our unique way of doing business. Our main focus is
            mutual growth and hence we empower every associate to learn, expand,
            dream and develop. It is because of Our Mission, Multimark India
            Group is the company where many want to join, stay and grow with and
            to <span>“Mark their Success”</span>. <br />
            We believe the world we want tomorrow starts with how we do business
            today. Through our steadfast commitment we make sure we just don’t
            talk about better future but also work towards it every day!! For
            this we follow certain principles that form the foundation of how we
            do business today and every day. We put these 4 principles at the
            center of every decision we make.
          </p>
        </div>
      </div>
      <div className={style.missionCardContainer}>
        {data.map((item) => (
          <MissionCard item={item} key={item.id}/>
        ))}
      </div>
    </>
  );
};

export default OurMission;
