import React, { useState } from "react";
import { IMAGES } from "../../../assests/index";
import style from "./accordian.module.css";

const Accordian = ({ item }) => {
  const { question, answer } = item;
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  return (
    <>
      <div className={style.container}>
        <div
          className={show ? style.anwserClose : style.answerOpen}
        >
          <div className={style.questionWrap}>
            <p className={style.questionContent}>{question}</p>
            <div className={style.btnWrap}>
              <button onClick={handleShow}>
                {show ? (
                  <div className={style.imgWrap}>
                    <img src={IMAGES.MINUS} alt="minus-icon" />
                  </div>
                ) : (
                  <div className={style.imgWrap}>
                    <img src={IMAGES.PLUS} alt="plus-icon" />
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className={style.answerWrap}>{show && <p>{answer}</p>}</div>
        </div>
      </div>
    </>
  );
};

export default Accordian;
