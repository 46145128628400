import React, { useState } from "react";
import { IMAGES } from "../../assests";
import { useHistory } from "react-router";
import styles from "./header.module.css";
import { Link } from "react-router-dom";

const menu = [
  {
    id: 0,
    menuItem: "Home",
    path: "/",
  },
  {
    id: 1,
    menuItem: "About Us",
    path: "/aboutUs",
  },
  {
    id: 2,
    menuItem: "Shop",
    path: "/shop",
  },
  // {
  //   id: 3,
  //   menuItem: "Insights",
  //   path: "/Insights",
  // },
  {
    id: 3,
    menuItem: "Partnership",
    path: "/partnerShip",
  },
  {
    id: 4,
    menuItem: "Contact",
    path: "/contact",
  },
];

function Header() {
  const history = useHistory();
  const [showSlide, setShowSlide] = useState(false);
  const handleShowSlide = () => {
    setShowSlide(!showSlide);
  };
  return (
    <nav className={styles.navbar_items}>
      <Link to="/">
        <img src={IMAGES.LOGO} alt="logo" className={styles.Logo} />
      </Link>
      <div className={styles.icon} onClick={handleShowSlide}>
        {showSlide ? (
          <img
            src={IMAGES.CLOSE}
            alt="close_icon"
            className={styles.mobile_burger}
          />
        ) : (
          <img
            src={IMAGES.BURGER}
            alt="burger_icon"
            className={styles.mobile_burger}
          />
        )}
      </div>
      <ul
        className={
          showSlide ? `${styles.nav} ${styles.nav_active}` : styles.nav
        }
      >
        {menu.map((item) => {
          return (
            <Link
              to={`${item.path}`}
              key={item.id}
              className={
                history.location.pathname === item.path
                  ? styles.menu_item_selected
                  : styles.menu_item
              }
            >
              {item.menuItem}
            </Link>
          );
        })}
        <li className={styles.btn}>
          <div className={styles.call_container}>
            <img
              src={IMAGES.CALL_BRANDED}
              alt="CALL_BRANDED"
              className={styles.branded}
            />
            <img
              src={IMAGES.CALL_BLACK}
              alt="CALL_BLACK"
              className={styles.black}
            />
          </div>
          {/* <p>
            +91 98451 95449 / <br></br>
            080 4169 5449
          </p> */}
          <div className={styles.numberWrap}>
            <a href="tel:9845195449">+91 98451 95449</a>
            <span>/</span><br />
            <a href="tel:08041695449">080 4169 5449</a>
          </div>
        </li>
      </ul>
    </nav>
    // <section>
    //   <div className={styles.mobile_header}>
    //     <Link to="/">
    //       <img src={IMAGES.LOGO} alt="logo" className={styles.mobile_Logo} />
    //     </Link>
    //     <img
    //       src={IMAGES.BURGER}
    //       alt="burger_icon"
    //       className={styles.mobile_burger}
    //     />
    //   </div>
    //   <div className={styles.desktop_header}>
    //    <div>
    //    <Link to='/'>
    //       <img src={IMAGES.LOGO} alt="logo" className={styles.desktop_logo} />
    //     </Link>
    //    </div>
    //     <div className={styles.menu_container}>
    //       {menu.map((item) => {
    //         return (
    //           <Link to={`${item.path}`} key={item.id} className={history.location.pathname === item.path ? styles.menu_item_selected : styles.menu_item}>
    //              {item.menuItem}
    //           </Link>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </section>
  );
}

export default Header;
