import React from "react";
import style from "./AddressCard.module.css";
import { IMAGES } from "../../../assests";

const AddressCard = () => {
  return (
    <>
      <div className={style.cardWrap}>
        <img src={IMAGES.CONTACT_CARD} alt="" />
        <div className={style.cardContentWrap}>
          <div className={style.cardAddress}>
            <img src={IMAGES.LOCATION_ICON} alt="" />
            {/* <LocationOnOutlined /> */}
            <div className="">
              <p className={style.cardHeader}>Address</p>
              <p className={style.address}>
                MultiMark India Group <br />
53/3, First Floor, Hospital Road, Bengaluru, Karnataka 560001. <br />
Landmark: Above Pet Planet, Next to Anand Diagnostic Lab.
              </p>
            </div>
          </div>
          <div className={style.contactMail}>
            <img src={IMAGES.CALL_ICON} alt="" />
            {/* <IoCallOutline /> */}
            <div className="">
              <p className={style.cardHeader}>Telephone</p>
              <div className={style.contactMailWrap}>
              <p>Landline: <a href="tel:080-41695449">080-41695449</a></p>
              <p>Office: <a href="tel:+91 88611 56721">+91 88611 56721</a></p>
                {/* <a href="tel:+91 98540 12345">+91 98540 12345</a>
              <a href="tel:+91 98800 12345">+91 98800 12345</a> */}
              </div>
            </div>
          </div>
          <div className={style.contactMail}>
            <img src={IMAGES.MAIL_ICON} alt="" />
            {/* <MailOutlineOutlined /> */}
            <div className="">
              <p className={style.cardHeader}>Email</p>
              <div className={style.contactMailWrap}>
                <a href="mailto:info@multimarkindiagroup.com ">
                info@multimarkindiagroup.com 
                </a>
                <a href="mailto:www.multimarkindiagroup.com">
                www.multimarkindiagroup.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressCard;
