import React from 'react'
import { Link } from 'react-router-dom'
import {IMAGES} from '../../assests'
import styles from './footer.module.css'

function Footer() {
 return (
  <section className={styles.footer}>
   <div className={styles.footer_main}>
    <div>
    <Link to='/'><img src={IMAGES.LOGO} alt='logo' className={styles.logo}/></Link>
    <div className={styles.social_media}>
         <a href='https://twitter.com/MultimarkIndia?ref_src=twsrc%5Etfw' target='_blank' rel="noreferrer">
     <span className={styles.icon}>
         <img src={IMAGES.TWITTER} alt='twitter-icon' />
         </span>
                  </a>
         <a href='https://www.facebook.com/MULTIMARK-INDIA-GROUP-10069122264199/' target='_blank' rel="noreferrer"> 
     <span className={styles.icon}><img src={IMAGES.FACEBOOK} alt='facebook-icon' /></span></a>
     <a href='mailto:multimarkindia@yahoo.com'> 
     <span className={styles.icon}><img src={IMAGES.FLIGHT} alt='flight-icon' /></span></a>
     <a href='https://www.instagram.com/multimark_india_group/' target='_blank' rel="noreferrer">
     <span className={styles.icon}><img src={IMAGES.INSTAGRAM} alt='instagram-icon' /></span></a>
    </div>
   </div>
   <div className={styles.footer_nav}>
    <ul className={styles.nav_items}>
        <li><Link to='/aboutUs' >About Us </Link></li>
        <li><Link to='contact'>Contact </Link></li>
     <li onClick={() => window.alert('This page is under construction')}>Shipping & Return</li>
    </ul>
    <ul className={styles.nav_items}>
     <li onClick={() => window.alert('This page is under construction')}>Press Releases </li>
     <li onClick={() => window.alert('This page is under construction')}>Store Policy </li>
     <li onClick={() => window.alert('This page is under construction')}>FAQs</li>
    </ul>
   </div>
   <div className={styles.newsletter_container}>
    <p>Newsletter</p>
    <input type='email' placeholder='Enter your e-mail address' className={styles.input}/>
    <button className={styles.btn}>Subscribe</button>
   </div>
   
   </div>
   <p className={styles.copyright}><span><img src={IMAGES.COPYRIGHT} alt="copyright-icon" /></span> All rights reserved MultiMark India Group</p>
  </section>
 )
}

export default Footer
