import React from 'react'
import styles from './categories.module.css'
import ProfileCard from '../../utilities/ProfileCard'

function Categories({btnText, categories, goTo }) {
  return (
    <section className={styles.category_section}>
      <h2>
        Pet <span>Categories</span>
      </h2>
      <div className={styles.category_container}>
        {categories.map((item) => (
          <ProfileCard item={item} type='categories' btnText={btnText} goTo={goTo} key={item.id}/>
        ))}
      </div>
    </section>
  );
}

export default Categories
