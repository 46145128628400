import React from 'react'
import styles from './ourBrands.module.css'
import {IMAGES} from '../../../assests'

function index() {
 return (
  <section className={styles.brands}>
    <div className={styles.brands_child}>
    <h2> Our <span>Brands</span></h2>
    <p>We trust only the best of the brands for your pets, where the food is healthy and fresh for your pets health. Out motto is to provide healthy food in afflordable prices.</p>
    <div className={styles.brands_container}>
     <img src={IMAGES.SMART_HEART} alt='smart-heart-logo' className={styles.smart_heart_img}/>
     {/* <img src={IMAGES.ME_O} alt='me-o-logo' className={styles.me_o_img}/> */}
     <img src={IMAGES.HAPPY_CAT} alt='happy-cat-logo' className={styles.happy_cat_img}/>
    </div>
    </div>
  </section>
 )
}

export default index
