import React from "react";
import style from "./partnerShipDetail.module.css";
import Button from "../../utilities/Button/index";
import { IMAGES } from "../../../assests";

const PartnerShipDetail = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.greyImgWrap}>
          <img src={IMAGES.GREY_BG} alt="" />
        </div>
        <div className={style.contentWrap}>
          <p className={style.contentHead}>
            <span>Retail</span> Partnership
          </p>
          <p className={style.contentDesc}>
            Multimark India Group delivers more than pet food. We’re your
            partners through every difficult challenges, delivery service to
            independent per retailers from Kashmir to Kanyakumari. <br /> From
            local pet shops, feed stores, supermarkets to regional chains,
            whether you are a small store or a large empire we are here for you.
            We’re here with top quality brands and hard to find products. And
            were here to help you understand your customers. We provide the
            knowledge and support to help our partners grow. <br /> Together we
            can help pets live happier and healthier and delight pet parents and
            help you grow your business.
          </p>
          <Button text="Meet our Team" />
        </div>
        <div className={style.contentWrap}>
          <p className={style.contentHead}>
            <span>Vendor</span> Partnership
          </p>
          <p className={style.contentDesc}>
            Our rich experience ability and our excellent infrastructure have
            given us the opportunity to handle the imports and distribution of
            various leading companies. Every company we work with gets special
            importance and if needs arise we also hire special people for
            servicing our valued clients. <br /> Today in addition to leading in
            different products, we have diversified into direct import of
            special products. We are constantly building our relationship with
            businesses around the world and source quality commodities from all
            over. <br /> Partner and join our Nationwide Network. We connect
            trusted brands and commitment retails who share our belief that pet
            are part of the family. Those connections help our retail and vendor
            partners succeed. As a team we are focused on establishing solid
            strategic partnership with our vendors built on honesty, integrity
            and trust. Partner with us and as you grow we will be right there
            with you in helping you mark your success.
          </p>
          <Button text="Meet our Team" />
        </div>
        <div className={style.yellowImgWrap}>
          <img src={IMAGES.YELLOW_RIGHT} alt="" />
        </div>
      </div>
    </>
  );
};

export default PartnerShipDetail;
