import React from "react";
import style from "./missionCard.module.css";

const MissionCard = ({ item }) => {
  const { title, desc, img } = item;
  return (
    <>
      <div className={style.card}>
        <div className={style.imgWrap}>
          <img src={img} alt="mission-icon" />
        </div>
        <h3 className={style.title}>{title}</h3>
        <p className={style.desc}>{desc}</p>
      </div>
    </>
  );
};

export default MissionCard;
