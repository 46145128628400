import React from "react";
import { IMAGES } from "../../../assests";
import style from "./aboutBannerContent.module.css";

const AboutBannerContent = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.contentWrap}>
          <p className={style.content}>
            <span>Our Motto is to Never</span>
            <br />
            Compromise with Quality
          </p>
          <p className={style.contentDesc}>
            We want to offer your pets compassionate and nurturing care by
            providing products that are safe, healthy and nutritious and to give
            our human clients peace of mind.
          </p>
        </div>
        <div className={style.ImgWrap}>
          <img src={IMAGES.BONE_HERO} alt="" />
          <div className={style.imgContent}>
            <span>TRY US</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBannerContent;
