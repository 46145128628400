import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ProductCard from "../../utilities/ProductCard";
import style from "./relatedProduct.module.css";

const RelatedProduct = ({ relatedProduct }) => {
  const history = useHistory();
  const productPath = (id) => {
    history.push(`/product/${id}`);
  };
  return (
    <>
      <div className={style.container}>
        <p className={style.relateHeader}>Related Product</p>
        <div className={style.relatedWrap}>
          {relatedProduct.length > 0 ? (
            relatedProduct.map((item, index) => {
              // eslint-disable-next-line array-callback-return
              if (index >= 3) return;
              return (
                <Link to={`/product/${item.id}`}>
                  <ProductCard item={item} btnText="Shop Now" goTo={productPath}/>
                </Link>
              );
            })
          ) : (
            <p className={style.noProducts}>No Related products found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default RelatedProduct;
