import React from "react";
import style from "./contactBannerContent.module.css";
import { IMAGES } from "../../../assests/index";

const ContactBannerContent = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.contentWrap}>
          <p className={style.bannerContent}>
            Happy to <span>welcome</span> you to our circle of{" "}
            <span>friends</span>{" "}
          </p>
          <div className={style.ImgWrap}>
            <img src={IMAGES.BONE_HERO} alt="" />
            <div className={style.imgContent}>
              <span>BOOK A TOUR</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBannerContent;
