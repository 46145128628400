import Logo from "./images/Logo.svg";
import DogsFood from "./images/Dogs_Food.png";
import DogsAmoeba from "./images/Dogs_Amoeba.png";
import YellowAmoeba from "./images/Yellow_Amoeba.png";
import Burger from "./images/Burger.svg";
import Twitter from "./images/Twitter.svg";
import Facebook from "./images/Facebook.svg";
import Flight from "./images/Flight.svg";
import Instagram from "./images/Instagram.svg";
import Copyright from "./images/Copyright.svg";
import Dogs from "./images/Dogs.png";
import Cats from "./images/Cats.png";
import CategoryBackground from "./images/Category_background.png";
import CategoryBackgroundMobile from "./images/Category_background_mobile.png";
import ContactCard from "./images/Contact_card.png";
import Fish from "./images/Fish.png";
import Map from "./images/Map.png";
import SmallPets from "./images/Small_pets.png";
import Birds from "./images/Birds.png";
import Women_Dog from "./images/Women_Dog.png";
import Star from "./images/Star.png";
import SmartHeart from "./images/SmartHeart.png";
import Right_arrow_head from "./images/Right_arrow_head.png";
import Left_arrow_head from "./images/Left_arrow_head.png";
import Orange_arrow_left from "./images/Orange_arrow_left.png";
import Orange_arrow_right from "./images/Orange_arrow_right.png";
import Product from "./images/Product.png";
import Me_O from "./images/Me-O.png";
import Bone_biscuit from "./images/Bone_biscuit.png";
import Happy_cat from "./images/Happy_cat.png";
import Star5 from "./images/Star5.svg";
import Quotes from "./images/Quotes.png";
import Vector_Mobile from "./images/Vector_mobile.png";
import Vector_Desktop from "./images/vector_desktop.png";
import BoneHero from "./images/bone_hero.png";
import Stick from "./images/Stick.png";
import Yellow_desktop from "./images/yellow_desktop.png";
import ReviewComment from "./images/review-Img.png";
import Yellow_right from "./images/yellow_right.png";
import Grey_Bg from "./images/grey-Bg.png";
import ANNA from "./images/anna.png";
import JOHN1 from "./images/john.png";
import JOHN2 from "./images/John2.png";
import JANE from "./images/jane.png";
import CUP from "./images/cup.svg";
import CUSTOMER from "./images/customer.svg";
import HEART from "./images/heart.svg";
import REWARD from "./images/reward.svg";
import SEAN from "./images/sean.png";
import PLUS from "./images/plus.svg";
import MINUS from "./images/minus.svg";
import Women_with_Dog from "./images/dog-with girl.png";
import GreyBg_right from "./images/greyBg-right.png";
import LocationIcon from "./images/locationIcon.png";
import CallIcon from "./images/CallIcon.png";
import MailIcon from "./images/mailIcon.png";
import FAIZAN from "./images/Faizan.png";
import VIDEO from "./images/video.png";
import PLAY from "./images/play.svg";
import GROOMING from "./images/Grooming.png";
import FLY_BIRD from "./images/fly_bird.png";
import ANIMALS from "./images/Animals.png";
import CLOSE from "./images/close.svg";
import CALL_BLACK from "./images/call_black.svg";
import CALL_BRANDED from "./images/call_branded.svg";
import Me_O_Persian_Adult_Cat_Food_Dry from "./images/Me-O Persian Adult Cat Food Dry.png";
import Me_O_Persian_Kitten_Cat_Food_Dry from "./images/Me-O Persian Kitten Cat Food Dry.png";
import Bentonite_Happy_Cat_Litter from "./images/Bentonite Happy Cat Litter.png";
import Kat_Kun_Stick_Chicken_Flavour from "./images/Kat Kun Stick Chicken Flavour.png";
import Kat_Kun_Stick_Salmon_Flavour from "./images/Kat Kun Stick Salmon Flavour.png";
import Kat_Kun_Stick_Tuna_Flavour from "./images/Kat Kun Stick Tuna Flavour.png";
import Kat_Kun_Stick_Shrimp_Flavour from "./images/Kat Kun Stick Shrimp Flavour.png";
import Smart_Heart_Puppy_Dog_Food from "./images/Smart Heart Puppy Dog Food.png";
import Smart_Heart_Adult_Dog_Food from "./images/Smart Heart Adult Dog Food.png";
import Goat_Milk_Powder from "./images/Goat Milk Powder.png";
import Happy_Bites_Dog_Biscuits from "./images/Happy Bites - Dog Biscuits.png";
import Smart_Heart_Rabbit_Food_Apple from "./images/Smart Heart Rabbit Food Apple.png";
import Smart_Heart_Rabbit_Food_Veggies_Cereals from "./images/Smart Heart Rabbit Food Veggies & Cereals.png";
import Smart_Heart_Rabbit_Food_Wildberry from "./images/Smart Heart Rabbit Food Wildberry.png";
import Smart_Heart_Rabbit_Food_Raspberry from "./images/Smart Heart Rabbit Food Raspberry.png";
import Smart_Heart_Hamster_Food from "./images/Smart Heart Hamster Food.png";
import Smart_Heart_Bird_Food_Budgies from "./images/Smart Heart Bird Food Budgies.png";
import Porpoise_Goldfish_Food_Pellet from "./images/Porpoise Goldfish Food Pellet.png";
import Porpoise_Tropical_Fish_Food_Pellet from "./images/Porpoise Tropical Fish Food Pellet.png";
import Maria_Ocean_Fish_in_gravy from "./images/Maria Ocean Fish in gravy.png";
import Maria_Chicken_and_Salmon_in_grav from "./images/Maria Chicken and Salmon in grav.png";
import VETO_Adult_Dry_Dog_Food from "./images/VETO Adult Dry Dog Food.png";
import Petideal_Creamy_Chicken_Scallo from "./images/Petideal Creamy Chicken & Scallo.png";
import Petideal_Creamy_Salmon from "./images/Petideal Creamy Salmon.png";
import Petideal_Creamy_Chicken_Shrimp from "./images/Petideal Creamy Chicken & Shrimp.png";
import Petideal_Creamy_Tuna from "./images/Petideal Creamy Tuna.png";
import Ginger_Tom_Fresh_Lamb from "./images/Ginger Tom Fresh Lamb.png";
import Ginger_Tom_with_Whole_Fish from "./images/Ginger Tom with Whole Fish.png";
import Butch_Blue_Label_Dog_and_Cat_Foo from "./images/Butch Blue Label Dog and Cat Foo.png";
import Butch_Blue_Label_Dog_Food from "./images/Butch Blue Label Dog Food.png";

export const IMAGES = {
  LOGO: Logo,
  DOGS_FOOD: DogsFood,
  DOGS_AMOEBA: DogsAmoeba,
  YELLOW_AMOEBA: YellowAmoeba,
  BURGER: Burger,
  INSTAGRAM: Instagram,
  FLIGHT: Flight,
  FACEBOOK: Facebook,
  TWITTER: Twitter,
  COPYRIGHT: Copyright,
  BIRDS: Birds,
  SMALL_PETS: SmallPets,
  MAP: Map,
  FISH: Fish,
  CONTACT_CARD: ContactCard,
  DOGS: Dogs,
  CATS: Cats,
  CATEGORY_BACKGROUND: CategoryBackground,
  CATEGORY_BACKGROUND_MOBILE: CategoryBackgroundMobile,
  PRODUCT: Product,
  HAPPY_CAT: Happy_cat,
  LEFT_ARROW_HEAD: Left_arrow_head,
  RIGHT_ARROW_HEAD: Right_arrow_head,
  ORANGE_ARROW_LEFT: Orange_arrow_left,
  ORANGE_ARROW_RIGHT: Orange_arrow_right,
  ME_O: Me_O,
  SMART_HEART: SmartHeart,
  STAR: Star,
  WOMEN_DOG: Women_Dog,
  BONE_BISCUIT: Bone_biscuit,
  STAR5: Star5,
  QOUTES: Quotes,
  VECTOR_MOBILE: Vector_Mobile,
  VECTOR_DESKTOP: Vector_Desktop,
  BONE_HERO: BoneHero,
  STICK: Stick,
  YELLOW_DESKTOP: Yellow_desktop,
  REVIEWCOMMENT: ReviewComment,
  YELLOW_RIGHT: Yellow_right,
  GREY_BG: Grey_Bg,
  ANNA: ANNA,
  JOHN1: JOHN1,
  JOHN2: JOHN2,
  CUP: CUP,
  CUSTOMER: CUSTOMER,
  HEART: HEART,
  REWARD: REWARD,
  SEAN: SEAN,
  JANE: JANE,
  PLUS: PLUS,
  MINUS: MINUS,
  WOMEN_WITH_DOG: Women_with_Dog,
  GREYBG_RIGHT: GreyBg_right,
  LOCATION_ICON: LocationIcon,
  CALL_ICON: CallIcon,
  MAIL_ICON: MailIcon,
  PLAY: PLAY,
  ANIMALS: ANIMALS,
  VIDEO: VIDEO,
  GROOMING: GROOMING,
  FLY_BIRD: FLY_BIRD,
  FAIZAN: FAIZAN,
  CLOSE: CLOSE,
  CALL_BLACK: CALL_BLACK,
  CALL_BRANDED: CALL_BRANDED,
  Me_O_Persian_Adult_Cat_Food_Dry: Me_O_Persian_Adult_Cat_Food_Dry,
  Me_O_Persian_Kitten_Cat_Food_Dry: Me_O_Persian_Kitten_Cat_Food_Dry,
  Bentonite_Happy_Cat_Litter: Bentonite_Happy_Cat_Litter,
  Kat_Kun_Stick_Chicken_Flavour: Kat_Kun_Stick_Chicken_Flavour,
  Kat_Kun_Stick_Salmon_Flavour: Kat_Kun_Stick_Salmon_Flavour,
  Kat_Kun_Stick_Tuna_Flavour: Kat_Kun_Stick_Tuna_Flavour,
  Kat_Kun_Stick_Shrimp_Flavour: Kat_Kun_Stick_Shrimp_Flavour,
  Smart_Heart_Puppy_Dog_Food: Smart_Heart_Puppy_Dog_Food,
  Smart_Heart_Adult_Dog_Food: Smart_Heart_Adult_Dog_Food,
  Goat_Milk_Powder: Goat_Milk_Powder,
  Happy_Bites_Dog_Biscuits: Happy_Bites_Dog_Biscuits,
  Smart_Heart_Rabbit_Food_Apple: Smart_Heart_Rabbit_Food_Apple,
  Smart_Heart_Rabbit_Food_Veggies_Cereals:
    Smart_Heart_Rabbit_Food_Veggies_Cereals,
  Smart_Heart_Rabbit_Food_Wildberry: Smart_Heart_Rabbit_Food_Wildberry,
  Smart_Heart_Rabbit_Food_Raspberry: Smart_Heart_Rabbit_Food_Raspberry,
  Smart_Heart_Hamster_Food: Smart_Heart_Hamster_Food,
  Smart_Heart_Bird_Food_Budgies: Smart_Heart_Bird_Food_Budgies,
  Porpoise_Goldfish_Food_Pellet: Porpoise_Goldfish_Food_Pellet,
  Porpoise_Tropical_Fish_Food_Pellet: Porpoise_Tropical_Fish_Food_Pellet,
  Maria_Ocean_Fish_in_gravy: Maria_Ocean_Fish_in_gravy,
  Maria_Chicken_and_Salmon_in_grav: Maria_Chicken_and_Salmon_in_grav,
  VETO_Adult_Dry_Dog_Food: VETO_Adult_Dry_Dog_Food,
  Petideal_Creamy_Chicken_Scallo: Petideal_Creamy_Chicken_Scallo,
  Petideal_Creamy_Salmon: Petideal_Creamy_Salmon,
  Petideal_Creamy_Chicken_Shrimp: Petideal_Creamy_Chicken_Shrimp,
  Petideal_Creamy_Tuna: Petideal_Creamy_Tuna,
  Ginger_Tom_Fresh_Lamb: Ginger_Tom_Fresh_Lamb,
  Ginger_Tom_with_Whole_Fish: Ginger_Tom_with_Whole_Fish,
  Butch_Blue_Label_Dog_and_Cat_Foo: Butch_Blue_Label_Dog_and_Cat_Foo,
  Butch_Blue_Label_Dog_Food: Butch_Blue_Label_Dog_Food,
};
