import React from "react";
import style from "./contact.module.css";
// import Header from "../../components/Header/index";
// import Footer from "../../components/Footer/index";
import OrderingNumber from "../../components/utilities/OrderingNumber";
import ContactBannerContent from "../../components/Contact/ContactBannerContent";
import ContactAddress from "../../components/landing/Contact Address";
import ContactForm from "../../components/Contact/ContactForm";
import Search from "../../components/utilities/Search";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

const Contact = () => {
  return (
    <>
      <MainContainer className={style.container}>
        {/* <Header /> */}
        <div className={style.bannerBg}>
          <ContactBannerContent />
        </div>
        <div className={style.orderingWrap}>
          <OrderingNumber />
        </div>
        <div className={style.searchWrap}>
          <Search />
        </div>
        <ContactForm />
        <ContactAddress />
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
};

export default Contact;
