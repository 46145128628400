import React, { useState } from "react";
import emailjs from 'emailjs-com';  
import Validate from "../../utilities/Validate";
import style from "./partnerContact.module.css";
import styles from '../../utilities/Button/button.module.css'

const PartnerContact = () => {
  const [contact, setContact] = useState({ 
    name: '',
    email: '',
    message:'',
    isChecked: true,
    partner: 'Retail'
   })
   const [errors, setErrors] = useState({})
  // let errors = {}  

   const onChange =  (e) => {
     setContact( {...contact, [e.target.name]:e.target.value})
     if(e.target.name === 'check') {
      setContact({...contact, isChecked: !contact.isChecked})
     }
     }
  const sendEmail = (e) => {
    let errors = Validate(contact)
    e.preventDefault();
    setErrors(errors)
    if(Object.keys(errors).length === 0) {
      emailjs.sendForm('service_obpyzfk', 'template_p4xw9qx', e.target, 'user_5S4BTvf4deXBJNINtUzGz')
      .then((result) => {
          console.log(result.text);
          setContact({ 
            name: '',
            email: '',
            message:'',
            isChecked: true,
            partner: 'Retail'
           })
          window.alert('Your requst submitted successfully')
      }, (error) => {
          console.log(error.text);
          window.alert('Your requst submission is unsuccessfull')
      });
    }
    if(errors?.isChecked){
      window.alert('Please select the checkbox')
    }
  };
  return (
    <>
      <div className={style.container}>
        <p className={style.contactHead}>Contact us for</p>
        <p className={style.radioHead}>Parnership form</p>
        <form className={style.formWrap} onSubmit={sendEmail}>
          <div className={style.radioContentWrap} onChange={onChange}>
            <div className={style.radioWrap}>
              <input type="radio" id="retail" name="partner"  value='Retail' defaultChecked={contact.partner === 'Retail'}/>
              <label htmlFor="retail">Retail Partnership</label>
            </div>
            <div className={style.radioWrap}>
              <input type="radio" id="vendor" name="partner" value='Vendor' defaultChecked={contact.partner === 'Vendor'}/>
              <label htmlFor='vendor'>Vendor Partnership</label>
            </div>
          </div>
          <div className={style.inputWrap}>
           <div>
           <label htmlFor="name">
              Name<span>*</span>
            </label>
            {errors?.name && <p>{errors.name}</p>}
           </div>
            <input type="text"  id='name' name='name' value={contact.name} onChange={onChange}/>
          </div>
          <div className={style.inputWrap}>
          <div>
            <label htmlFor="email" >
              Email<span>*</span>
            </label>
            {errors?.email && <p>{errors.email}</p>}
          </div>
            <input type="email" id='email' name='email' value={contact.email} onChange={onChange} />
          </div>
          <div className={style.inputWrap}>
            <label htmlFor='message'>Your Message</label>
            <textarea cols="30" rows="4" name='message' id='message' value={contact.message} onChange={onChange} />
          </div>
          <div className={style.checkboxWrap}>
            <input type="checkbox" name='check' defaultChecked={contact.isChecked} onClick={onChange}/>
            <span>
              By submitting this form, I agree to receive communications from
              MultiMark in connection with my enquiry.
            </span>
          </div>
          <button className={styles.btn} onSubmit={sendEmail}> Submit </button>
        </form>
      </div>
    </>
  );
};

export default PartnerContact;
