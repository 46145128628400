import React from "react";
import { IMAGES } from "../../../assests";
import Accordian from "../../utilities/Accordian";
import style from "./whorwe.module.css";

const WhoAreWe = ({ questions }) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.contentWrap}>
          <h2>
            Who are <span>We?</span>
          </h2>
          <p>
            Multimark India Group is an International Trade Company specialized
            in providing import and distribution of pet supplies in India.{" "}
            <br />
            We are a pan-India ISO certified company committed to bringing the
            best of global pet care to Indian pets. So that our pets can get the
            best!! Together we want to create a world, where every pet is happy,
            healthy and safe.
          </p>
        </div>
        <div className={style.accordionBg}>
          {questions.map((item) => (
            <Accordian item={item} key={item.id}/>
          ))}
        </div>
        <div className={style.imgWrap}>
          <img src={IMAGES.GREYBG_RIGHT} alt="" />
        </div>
      </div>
    </>
  );
};

export default WhoAreWe;
