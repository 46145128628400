import React from "react";
import { IMAGES } from "../../assests";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer/index";
import BannerBgContent from "../../components/utilities/BannerBgContent";
import style from "./partnership.module.css";
import OrderingNumber from "../../components/utilities/OrderingNumber";
import PartnerShipDetail from "../../components/PartnerShip/PartnerShip Detail";
import PartnerContact from "../../components/PartnerShip/Partner Contact";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

const contentData = {
  contentHeadSpan: "We Believe in",
  contentHead: "Long Run Relationship",
  contentDesc:"We just do not sell products, but also happiness and health. If you have the same goal as ours, we offer you to join us to achive he goal of spreading happiness.",
  contentImg: IMAGES.BONE_HERO,
  contentImgData: "BOOK A TOUR",
};

const PartnerShip = () => {
  return (
    <>
      <MainContainer className={style.container}>
        {/* <Header /> */}
        <div className={style.bannerBg}>
          <BannerBgContent contentData={contentData} />
        </div>
        <div className={style.orderingWrap}>
          <OrderingNumber />
        </div>
        <PartnerShipDetail />
        <PartnerContact />
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
};

export default PartnerShip;
