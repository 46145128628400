import React from "react";
import Search from "../../utilities/Search";
import style from "./pageSearch.module.css";

const PageSearch = ({indexOfFirst, indexOfLast, length, term, handleTerm, onSubmit}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.pageCountWrap}>
          <p>
            Showing <span>{indexOfFirst + 1}</span>–<span>{indexOfLast > length ? length : indexOfLast}</span> of <span> {length} </span> results
          </p>
        </div>
        <Search term={term} handleTerm={handleTerm} onSubmit={onSubmit}/>
      </div>
    </>
  );
};

export default PageSearch;
