/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import EventCard from "../../components/insights/EventCard";
import { IMAGES } from "../../assests";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import Testimonies from '../../components/insights/Testimonies';
import BannerBgContent from "../../components/utilities/BannerBgContent";
import OrderingNumber from "../../components/utilities/OrderingNumber";
import Search from "../../components/utilities/Search";
import Pagination from "../../components/utilities/Pagination";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import style from "./insights.module.css";
import MainContainer from "../../components/utilities/MainContainer/MainContainer";

const data = [
  {
    id: 1,
    title: "GROOMING",
    desc: "Tips and tricks: Puppy’s First Grooming",
    date: "January 10, 2018",
    img: IMAGES.GROOMING,
  },
  {
    id: 2,
    title: "CATS",
    desc: "Cat Health – Keeping Them Purr-fect",
    date: "January 10, 2018",
    img: IMAGES.ANIMALS,
  },
  {
    id: 3,
    title: "GROOMING",
    desc: "Tips and tricks: Puppy’s First Grooming",
    date: "January 10, 2018",
    img: IMAGES.GROOMING,
  },
  {
    id: 4,
    title: "CATS",
    desc: "Cat Health – Keeping Them Purr-fect",
    date: "January 10, 2018",
    img: IMAGES.ANIMALS,
  },
  {
    id: 5,
    title: "GROOMING",
    desc: "Tips and tricks: Puppy’s First Grooming",
    date: "January 10, 2018",
    img: IMAGES.GROOMING,
  },
  {
    id: 6,
    title: "CATS",
    desc: "Cat Health – Keeping Them Purr-fect",
    date: "January 10, 2018",
    img: IMAGES.ANIMALS,
  },
];

const category = [
  "Tips and Tricks: Puppy’s First Grooming",
  "Cat Health - Keeping Them Purr-fect",
  "14 Foods You Can Share with Your Dog",
  "Best Summer Activities for Dog Owners",
];

const Archives = ["Oct - 2021", "Sept - 2021", "Aug - 2021"];

const testimonials = [
  {
    id:1,
    name: 'Faizan Khan',
    location: 'Bengaluru',
    testimony: ' My buddy loves the food. He is all hail and healthy since we switched to your products. It’s good that we get our order delivered on time.',
    image: IMAGES.FAIZAN
  },
  {
    id:2,
    name: 'Salman Khan',
    location: 'Bengaluru',
    testimony: ' My buddy loves the food. He is all hail and healthy since we switched to your products. It’s good that we get our order delivered on time.',
    image: IMAGES.FAIZAN
  },
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const contentData = {
  contentHeadSpan: "Know more",
  contentHead: "about us",
  contentDesc:"There are lot of things we would like to share with you all. Your love and support is the one which drive us. Help us serve you more and in better fashion.",
  contentImg: IMAGES.BONE_HERO,
  contentImgData: "BOOK A TOUR",
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      className={active ? style.active : style.inactive}
      onClick={() => onClick()}
    >
    </li>
  );
};

function Insights() {
  const [posts, setPosts] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(4);

  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = posts.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <>
      <MainContainer>
        {/* <Header /> */}
        <div className={style.bannerBg}>
          <BannerBgContent contentData={contentData} />
        </div>
        <div className={style.orderingWrap}>
          <OrderingNumber />
        </div>
        <div className={style.searchWrap}>
          <Search />
        </div>
        <section className={style.event_category_Section}>
        <div className={style.eventCategoryWrap}>
          <div className={style.cards_container}>
          <EventCard data={currentPosts} />
            <Pagination
              itemsPerPage={postPerPage}
              totalItems={posts.length}
              paginate={paginate}
              currentPage={currentPage}
              nextPage={nextPage}
              prevPage={prevPage}
            />
           <div className={style.yellow_bg_mobile}>
          </div>
          </div>
          <div className={style.categoriesWrap}>
            <section>
              <div className={style.category_section}>
                <p className={style.heading}>Recent Posts</p>
                <div className={style.underline}></div>
                <ul>
                  {category.map((item) => (
                    <li
                      key={item}
                      className={style.list_item}
                      // onClick={() => getProductsByCategory(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section>
              <div className={style.category_section}>
                <p className={style.heading}>Archives</p>
                <div className={style.underline}></div>
                <ul>
                  {Archives.map((item) => (
                    <li
                      key={item}
                      className={style.list_item}
                      // onClick={() => getProductsByCategory(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </section>

          </div>    
        </div>
        <div className={style.yellow_bg}></div>
        </section>
        
        {/* <section className={style.video_section}>
          <p>We have <span>many</span> things to <span>share</span></p>
          <Carousel responsive={responsive}  arrows={false}
            showDots
            dotListClass={style.dots}
            customDot={<CustomDot />}>
            <div className={style.video}>
            <img src={IMAGES.VIDEO} alt='video' />
            </div>
            <div className={style.video}>
            <img src={IMAGES.VIDEO} alt='video' width={350}  />
            </div>
            <div className={style.video}>
            <img src={IMAGES.VIDEO} alt='video' width={350}  />
            </div>
         </Carousel>
        </section>
        <section className={style.testimonial_section}>
          <p className={style.testimony_header}>Testimonials</p>
          <Carousel responsive={responsive} arrows={false} autoPlay infinite autoPlaySpeed={5000}>
            {testimonials.map(item => <Testimonies image={item.image} location={item.location} name={item.name} testimony={item.testimony} key={item.id}/>)}
          </Carousel>
        </section> */}
        {/* <Footer /> */}
      </MainContainer>
    </>
  );
}

export default Insights;
