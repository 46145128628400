import React from 'react'
import styles from './button.module.css'

function Button({text, id, goTo, href}) {
    if(!href && !id) {
        return <button className={styles.btn}> {text}</button>}
    if(href) {
    return <a href={`tel:${href}`}><button className={styles.btn}>{text}</button> </a>
    }
 return (
    <button className={styles.btn} onClick={() => goTo(id)}> {text}</button>
 )
}

export default Button
