import React, {useEffect, useState} from 'react'
import ProductCard from '../../utilities/ProductCard'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function Slider({data, btnText, goTo}) {
  const [products, setProducts]= useState(data)
  useEffect(() => {
    let items= []
    data.forEach(item => {
    items = [...items, ...item.categoryItems]
  })  
  setProducts(items)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 return (<Carousel responsive={responsive}>
   {products.slice(0,8).map(item => <div style={{ width:300,
  margin:'0px auto'}} key={item}><ProductCard item={item} btnText={btnText} goTo={goTo}/></div>)}
 </Carousel>
  
 )
}

export default Slider
