import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assests";
import style from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.aboutBg}>
          <div className={style.contentWrap}>
            <p className={style.aboutHeader}>
              About <span>Us</span>
            </p>
            <div className={style.aboutDescWrap}>
              <p className={style.aboutDesc}>
                Multimark India Group is an International Trade Company
                specialized in providing import and distribution of pet supplies
                in India. <br /> We are a pan-India ISO certified company
                committed to bringing the best of global pet care to Indian
                pets. So that our pets can get the best!! Together we want to
                create a world, where every pet is happy, healthy and safe.
              </p>
            </div>
            <div className={style.readBtnWrap}>
              <Link className={style.readBtn} to="/">
                Read More
              </Link>
            </div>
          </div>
          <div className={style.foodWrap}>
            <img src={IMAGES.DOGS_FOOD} alt="" />
          </div>
        </div>

        <div className={style.desktopWrap}>
          <div className={style.imgWrap}>
            <img
              className={style.yellowImg}
              src={IMAGES.YELLOW_DESKTOP}
              alt=""
            />
            <div className={style.foodImg}>
              <img src={IMAGES.DOGS_FOOD} alt="" />
            </div>
            <div className={style.stickImg}>
              <img src={IMAGES.STICK} alt="" />
            </div>
          </div>
          <div className={style.desktopContentWrap}>
            <p className={style.desktopAboutHeader}>
              About <span>Us</span>
            </p>
            <div className={style.desktopAboutDescWrap}>
              <p className={style.desktopAboutDesc}>
                Multimark India Group is an International Trade Company
                specialized in providing import and distribution of pet supplies
                in India. <br /> We are a pan-India ISO certified company
                committed to bringing the best of global pet care to Indian
                pets. So that our pets can get the best!! Together we want to
                create a world, where every pet is happy, healthy and safe.
              </p>
            </div>
            <div className={style.desktopReadBtnWrap}>
              <Link className={style.desktopReadBtn} to="/">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
