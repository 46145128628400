import React from 'react'
import {IMAGES} from '../../../assests'
import styles from './productCard.module.css'
import Button from '../Button'

function ProductCard({item, btnText, goTo}) {
  // console.log(item);
 return (
   <div className={styles.card}>
     <div className={styles.image_container}>
       {" "}
       <img src={item.img} alt="product" className={styles.product_image} />
     </div>
     <div className={styles.product_info}>
       <p className={styles.product_title}>{item.title}</p>
       <span>
         {" "}
         {item.ratings?.map((item, index) => {
           return <img src={IMAGES.STAR5} alt="star" key={index} />;
         })}
       </span>
       {/* <p><del>&#8377;{item.oldPrice}</del> &#x20B9;{item.newPrice}</p> */}
       <Button text={btnText} goTo={goTo} id={item.id}/>
     </div>
   </div>
 );
}

export default ProductCard
