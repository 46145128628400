import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import Header from '../../Header';
import Footer from '../../Footer';
import styles from './mainContainer.module.css';

function MainContainer({children}) {
 const [headStyle, setHeadStyle] = useState({
  background : 'transparent'
})
const listenScrollEvent = e => {
  if (window.scrollY > 50) {
    setHeadStyle({
      background : 'white',
      boxShadow: '1px 1px 10px gray'
    })
  } else {
    setHeadStyle({
      background : 'transparent'
    })
  }
}

const location = useLocation();

useEffect(() => {
  window.scrollTo(0, 0);
  window.addEventListener('scroll', listenScrollEvent)
}, [location])

 return (
  <section>
   <div className={styles.header} style={headStyle}>
    <Header />
   </div>
   {children}
   <Footer />
  </section>
 )
}

export default MainContainer
