import React from 'react'
import {IMAGES} from '../../../assests'
import styles from './pagination.module.css'

function Pagination({itemsPerPage, totalItems, paginate, currentPage, nextPage, prevPage}) {

 const pageNumbers = []

 for(let i =1; i<= Math.ceil(totalItems / itemsPerPage); i++) {
  pageNumbers.push(i)
 }
 return (
  <nav className={styles.nav}>
   <span className={styles.arrow}>
   {currentPage !== 1 && <img src={IMAGES.ORANGE_ARROW_LEFT} alt="left-arrow"  onClick={prevPage} className={styles.prev}/>}
   </span>
   <ul className={styles.pages}>
    {
     // eslint-disable-next-line jsx-a11y/anchor-is-valid
     pageNumbers.map((number) => <li key={number} ><a href="" onClick={() => paginate(number)} className={currentPage===number ? styles.selected : styles.unSelected}>{number}</a></li>)
    }
   </ul>
   <span className={styles.arrow}>
   {currentPage !== Math.ceil(totalItems / itemsPerPage) && pageNumbers.length !== 0 && pageNumbers.length !== 1  ? <img src={IMAGES.ORANGE_ARROW_RIGHT} alt="right-arrow" onClick={nextPage} className={styles.next}/> : null}
   </span>
   
  </nav>
 )
}

export default Pagination
